import { AUTO_SUGGEST_POI_LIST, HOTEL_CARD_AREA } from './constants';

const getHotelCardArea = ({ isMapPanelHotel, isRecommendHotel }) => {
  if (isMapPanelHotel) {
    return HOTEL_CARD_AREA.MAP_PANEL;
  }

  if (isRecommendHotel) {
    return HOTEL_CARD_AREA.RECOMMENDED;
  }
  return HOTEL_CARD_AREA.UNSET_HOTEL_CARD_AREA;
};

export const HOTELS_FRONTEND_SCHEMA_NAME = 'hotels_frontend.HotelsAction';
export const HOTELS_FRONTEND_MINIEVENT_NAME = 'HotelsFrontendHotelsAction';

export const buildAdditionalInfoDayViewSearch = ({
  IsSearchWithChildren,
  entityID,
  entityName,
  entityType,
  filters,
}) => ({
  day_view_search: {
    entity_id: entityID,
    entity_name: entityName,
    is_search_with_children: IsSearchWithChildren,
    filters,
    entity_type: entityType,
  },
});

export const buildAdditionalInfoSearchResultSelectedMessage = ({
  hasCoupon,
  hotelId,
  isCugDeal,
  isGoToHD,
  isMainPrice,
  isMapPanelHotel,
  isRecommendHotel,
  isUpSortHotels,
  mainPriceArea,
  pageName,
  partnerId,
  position,
  price,
  searchId,
}) => ({
  search_result_selected: {
    has_cug_deal: isCugDeal,
    has_coupon: hasCoupon,
    is_up_sorted_hotels: isUpSortHotels,
    hotel_id: parseInt(hotelId, 10),
    is_go_to_hd: isGoToHD,
    is_main_price: isMainPrice,
    is_recommend_hotel: isRecommendHotel,
    hotel_card_area: getHotelCardArea({
      isRecommendHotel,
      isMapPanelHotel,
    }),
    main_price_area: mainPriceArea,
    partner_id: partnerId,
    position,
    price,
    search_id: searchId,
    page_name: pageName,
  },
});

export const buildAdditionalInfoAutoSuggestPoiClick = ({ cityId, poiId }) => ({
  auto_suggest_poi_click: { city_id: cityId, poi_id: poiId },
});

export const buildAdditionalInfoAutoSuggestPois = (suggestion) => {
  let poi;
  const poiList = AUTO_SUGGEST_POI_LIST.reduce((acc, curr, index) => {
    poi = suggestion.pois[index] || {};
    acc[curr] = poi.entityId || '';
    return acc;
  }, {});
  return {
    auto_suggest_pois: {
      city_id: suggestion.entityId,
      ...poiList,
    },
  };
};
